@import "../../styleFunctions/mixins";

.becomeFleetMemberPage {
  @include flexContainer;
  @include backgroundImage($image: url(../../assets/aboutSliderOne.png));
  min-height: 75rem;
  padding-bottom: 10rem;
  position: relative;
  padding-inline: 2rem;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  > div {
    > div {
      justify-content: center;
    }
  }
  .becomeFleetMemberInner {
    position: relative;
    padding: 50px 40px 30px;
    background-color: #ffffff;
    box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
      0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
      0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    flex-direction: column;
    width: 100%;
    // max-width: 60rem;
    z-index: 1;
    .loaderBox {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgb(255, 255, 255, 0.8);
      @include flexContainer($gap: 0);
      z-index: 3;
      top: 0;
      left: 0;
      border-radius: 1.1rem;
    }
    .successBox {
      @include flexContainer;
      flex-direction: column;
      img {
        max-width: 25rem;
      }
      h3 {
        text-align: center;
        font-weight: 600;
        font-size: 2.2rem;
        line-height: 3.2rem;
      }
      p {
        text-align: center;
      }
    }
    > h2 {
      margin-bottom: 7rem;
      position: relative;
      text-align: center;
      &:after {
        content: "";
        background-color: var(--primaryColor);
        width: 10rem;
        height: 0.4rem;
        position: absolute;
        bottom: -2rem;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .becomeFleetMemberPage {
    .becomeFleetMemberInner {
      padding: 2rem;
    }
    > div {
      padding-inline: 0px !important;
    }
  }
}
