@import "../../../styleFunctions/mixins";

.stepPointerBlock {
  @include flexContainer;
  gap: 8rem !important;
  width: 100%;
  // position: absolute;
  // top: 2rem;
  .stepBlock {
    @include flexContainer($gap: 0.6rem);
    flex-direction: column;
    position: relative;
    &:last-child {
      > span {
        &:nth-of-type(1) {
          &::after {
            display: none;
          }
        }
      }
    }
    span {
      &:nth-of-type(1) {
        width: 4rem;
        height: 4rem;
        background-color: var(--whiteColor);
        border: 0.3rem solid var(--primaryColor);
        color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 1.6rem;
        font-weight: 500;
        z-index: 3;
        background-color: #fff;
        // position: relative;
        // &::after {
        //   content: "";
        //   width: 18rem;
        //   height: 0.6rem;
        //   border-block: 0.2rem solid var(--primaryColor);
        //   position: absolute;
        //   top: 25%;
        //   transform: translate(0, -25%);
        //   right: -13rem;
        //   z-index: 2;
        // }
      }
      &:nth-of-type(2) {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
      }
      &.active {
        background-color: var(--primaryColor);
        color: var(--whiteColor);
        &::after {
          background-color: var(--primaryColor);
        }
      }
    }
  }
  // hr {
  //   width: 100%;
  // }
  // .middleBorder {
  //   width: 8.1rem;
  //   height: 0.6rem;
  //   flex: auto;
  //   border-block: 0.2rem solid var(--primaryColor);
  //   // position: absolute;
  //   // top: 50%;
  //   // left: -8rem;
  //   display: block;
  // }
}

@media only screen and (max-width: 900px) {
  .stepPointerBlock {
    display: none;
  }
}
