@import "../../../../styleFunctions/mixins";

.fleetCardMemberForm {
  @include flexContainer($gap: 4rem);
  flex-direction: column;
}

.becomeFleetMemberForm {
  width: 100%;
  > h4 {
    font-size: 2.2rem;
    line-height: 3rem;
    margin: 0;
    font-weight: 600;
  }
  > div {
    @include flexContainer($gap: 2rem);
    width: 100%;
    > div {
      @include flexContainer($gap: 0.5rem);
      flex-direction: column;
      width: 100%;
      position: relative;
      .errorText {
        position: absolute;
        bottom: -3.5rem;
        left: 0;
      }
    }
  }
  .formBtnContainer {
    button {
      margin: 0 0 0 auto;
      &:nth-of-type(1) {
        margin: 0 auto 0 0;
      }
    }
  }
  .oneBtnContainer {
    button {
      margin: 0 0 0 auto !important;
    }
  }
  .formTitle {
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin: 0;
  }
  .uploadFileContainer {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
}

.fieldWrapper {
  @include flexContainer;
  > div {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .fieldWrapper {
    flex-direction: column;
  }
  .becomeFleetMemberForm {
    gap: 4rem;
    > div {
      flex-direction: column;
      gap: 4rem;
    }
    .formBtnContainer {
      flex-direction: row;
      > button {
        padding: 1rem 2rem;
      }
    }
    .uploadFileContainer {
      span {
        font-size: 1.4rem;
      }
    }
    .checkboxContainer {
      span {
        &:nth-of-type(1) {
          width: 30px !important;
        }
      }
    }
  }
}
