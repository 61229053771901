@import "../../styleFunctions/mixins";

.fleetCardPage {
  .bannerSection {
    // @include backgroundImage($image: url(../../assets/xpressFleetBanner.webp));
    // background-color: #000;
    // min-height: 60rem;
    position: relative;
    &:before {
      content: "";
      height: 4rem;
      width: 4rem;
      //   background-color: var(--primaryColor);
      border: 2rem solid #c9a745;
      position: absolute;
      bottom: -1.8rem;
      right: calc(25% - 3rem);
      clip-path: polygon(23% 0, 100% 50%, 24% 100%, 0% 100%, 0 47%, 0% 0%);
    }
    &:after {
      content: "";
      width: 100%;
      background-color: #c9a745;
      height: 4rem;
      bottom: -1.8rem;
      position: absolute;
      max-width: 75%;
      left: 0;
    }
  }
  .benefitsFleetCard {
    padding-bottom: 10rem;
    .benefitsTitle {
      h2 {
        text-align: center;
        font-size: 3.4rem;
      }
      margin-bottom: 5rem;
    }
    .benefitsContent {
      align-items: center;
      .benefitInfo {
        @include flexContainer($gap: 4rem);
        flex-direction: column;
        flex-wrap: wrap;
        > h2 {
          text-align: left;
          margin: 0;
          font-size: 2.8rem;
          width: 100%;
        }
        .benefitBlock {
          @include flexContainer($gap: 4rem, $justifyContent: flex-start);
          width: 100%;
          > div {
            &:nth-of-type(1) {
              flex: 0 0 7rem;
              width: 7rem;
            }
            // &:nth-of-type(2) {
            //   flex: 1 0 50rem;
            // }
          }
          // img {
          //   max-width: 7rem;
          // }
          ul {
            list-style: disc;
            li {
              font-size: 1.6rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .applyTodaySection {
    background-color: #e9e9e9;

    > div {
      padding: 5rem 0;
      > div {
        text-align: center;
        > div {
          padding-inline: 4rem;
          > a {
            max-width: fit-content;
            margin: 0 auto;
            background-color: #c9a745;
            border-color: #c9a745;
            color: #fff !important;
            &:hover {
              background-color: #c9a745;
              border-color: #c9a745;
            }
          }
          h2 {
            font-size: 2.8rem;
            margin: 0;
            text-align: center;
          }
          .applySteps {
            margin-block: 3rem;
            @include flexContainer(
              $justifyContent: space-between,
              $alignItems: flex-start
            );
            flex-wrap: wrap;
            max-width: 80rem;
            margin-inline: auto;
            .stepInfo {
              @include flexContainer($gap: 1rem);
              flex-direction: column;
              flex: 1;
              position: relative;
              z-index: 1;
              h3 {
                font-size: 2.2rem;
                color: var(--primaryColor);
                margin: 0;
                line-height: normal;
                border: 0.3rem solid var(--primaryColor);
                border-radius: 50%;
                padding: 0.8rem 1.5rem;
                aspect-ratio: 1;
              }
              p {
                font-size: 1.6rem;
                margin: 0;
                text-align: center;
                a {
                  color: var(--primaryColor) !important;
                  font-weight: 600;
                }
              }
              &:after {
                content: "";
                width: calc(100% - 20px);
                height: 0.2rem;
                background-color: #c9a745;
                position: absolute;
                top: 21px;
                right: -50%;
                z-index: 0;
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
          }
          &:nth-of-type(1) {
            position: relative;
            &:after {
              content: "";
              width: 2px;
              height: 200px;
              background-color: #c9a745;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
          &:nth-of-type(2) {
            h5 {
              font-size: 2rem;
              margin-bottom: 2rem;
              // text-align: left;
              font-weight: 600;
            }
            ul {
              list-style: disc;
              padding-left: 20px !important;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 1rem;
              li {
                font-size: 1.6rem;
                font-weight: 500;
                width: 100%;
                text-align: left;
                a {
                  color: #c9a745 !important;
                  font-weight: 600;
                }
              }
            }
            p {
              font-size: 1.6rem;
              margin: 2rem 0 0;
              text-align: center;
              font-weight: 600;
              a {
                color: #c9a745 !important;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  .applicationSendInfo {
    padding-block: 4rem;
    padding-inline: 2rem;
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .fleetCardPage {
    .bannerSection {
      // min-height: 25rem;
    }
    .benefitsFleetCard {
      .benefitsContent {
        .benefitInfo {
          .benefitBlock {
            > div {
              &:nth-of-type(2) {
                flex: unset;
              }
            }
          }
        }
      }
    }
    .applyTodaySection {
      > div {
        > div {
          row-gap: 60px;
          > div {
            padding-inline: 10px;
            h5 {
              line-height: 3.2rem;
            }
            &:nth-of-type(1) {
              &:after {
                width: 200px;
                height: 2px;
                top: unset;
                right: 50%;
                transform: translateX(50%);
                bottom: -30px;
              }
            }
            .applySteps {
              .stepInfo {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
